import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import ScopesStatusStyle from '../css/ScopesStatus.module.css'
import { API_DEFAULT } from "../services/settings";

const ScopesStatus = forwardRef((props, ref) => {
    const { setAccessToken, accessToken, getAccessTokenWithRefresh, setShowLoader } = props
    const [scopesProcess, setScopesProcess] = useState([])

    // Función que obtiene un objeto[] con la información de todos los visores que puede ver el usuario.
    const getScopesProcess = async (access) => {
        setShowLoader(true)
        const res = await fetch(
            `${API_DEFAULT}/usuarios/procesos`, {
            headers: {
                "Authorization": "Bearer " + access
            }
        })

        res.json().then(
            async data => {
                // Ordenar el objeto   
                if (data['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                    }
                } else {
                    let sortedData = data.sort((a, b) => {
                        if (a.fecha_creado > b.fecha_creado) {
                            return -1;
                        }
                        if (a.fecha_creado < b.fecha_creado) {
                            return 1;
                        }
                        return 0;
                    })

                    const now = new Date()
                    // Función para eliminar objetos creados hace más de 24 horas
                    const filterOldObjects = (arr) => {
                        return arr.filter(item => {
                            const createdDate = new Date(item.fecha_creado)
                            const hoursDiff = (now - createdDate) / (1000 * 60 * 60)
                            return hoursDiff <= 24;
                        });
                    };

                    const filteredData = filterOldObjects(sortedData);

                    setScopesProcess(filteredData)
                    setShowLoader(false)
                }
            }
        )
    }

    useEffect(() => {
        getScopesProcess(accessToken)
    }, [accessToken]);

    useImperativeHandle(ref, () => ({
        getScopesProcess
    }));

    const process = scopesProcess.length > 0 ? scopesProcess.map((scope) => {
        return (
            <div className={ScopesStatusStyle["progress-item"]}>
                <div className={ScopesStatusStyle["progress-info"]}>
                    <span className={ScopesStatusStyle["username"]}>{'@' + scope.username.split('@')[0]}</span>
                    <span className={ScopesStatusStyle["filename"]}>{scope.nombre}</span>
                </div>
                <div className={ScopesStatusStyle["progress-bar"]}>
                    {scope.estado === 2 ? (
                        <div className={ScopesStatusStyle["progress"]} style={{ width: '100%', backgroundColor: 'transparent', 'color': '#b91717' }}>☓ Error generando consulta</div>
                    ) : scope.estado === 3 ? (
                        <div className={ScopesStatusStyle["progress"]} style={{ width: '100%', backgroundColor: 'transparent', 'color': '#346e9b' }}>ⓘ Consulta en cola de procesos</div>
                    ) : (
                        <div className={ScopesStatusStyle["progress"]} style={{ width: `${scope.avance}%`, backgroundColor: `${scope.avance != '0' ? '#64a454' : 'transparent'}` }}>{scope.avance}%</div>
                    )}
                </div>
            </div>
        );
    }) : <div className={ScopesStatusStyle["no-scopes-process"]}>No hay alcances para mostrar</div>;

    const hideScopesStatus = () => {
        const scopesStatus = document.getElementById('scopes-status')
        scopesStatus.classList.remove(ScopesStatusStyle["hide-scopes-status"])
        scopesStatus.classList.toggle(ScopesStatusStyle["show-scopes-status"])
    }

    return (
        <>
            <div className={ScopesStatusStyle["scopes-status"]} id="scopes-status">
                <div className={ScopesStatusStyle["layer-hide-menu"]} onClick={() => hideScopesStatus()}></div>
                <div className={ScopesStatusStyle["scopes-status-container"]} id="scopes-status-container">
                    <div className={ScopesStatusStyle["scopes-status-header"]}>
                        <h2>Estado de consultas</h2>
                    </div>

                    <div className={ScopesStatusStyle["scopes-status-container-scroll"]}>
                        <div className={ScopesStatusStyle["progress-container"]}>
                            {process}
                        </div>
                    </div>

                    <div className={ScopesStatusStyle["scopes-status-button-container"]}>
                        <div className={ScopesStatusStyle["update-status"]} onClick={() => getScopesProcess(accessToken)}>
                            <ion-icon name="reload-outline"></ion-icon>
                            <span>Actualizar</span>
                        </div>
                        <button className={ScopesStatusStyle["scopes-status-button-cancel"]} onClick={() => hideScopesStatus()}>CERRAR</button>
                    </div>

                </div>
            </div>
        </>
    );
});

export default ScopesStatus;
